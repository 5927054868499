
.js-loading-svg--block{
    background-image: url("../images/spinner.svg");
    background-repeat: no-repeat;
    background-position: center;

    background-size: 30% 30%;
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
      animation: spin-loading 1s infinite linear;
      background-color: transparent;
    background-size: 90% 90%;
}
}