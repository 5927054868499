button[name="contact_submit"]:hover{
	color:#69EBF1 !important;
}
#contact_form input, textarea{
	padding-left: 0.5rem !important;
}

#contact_form .inputrow{
	margin-bottom: 1rem;
}
#contact_form input, textarea{
	margin-bottom: 0 !important;
}

#contact_form .errorMessage{
	color:red !important;
	font-weight: bold;
}

#contact_form .hidden{
	display:none;
}