.video-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; }

.video-container iframe, .video-container object, .video-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.patch-info-container{
  font-size:1.0em;
}
.patch-price{
  font-size:1.8em;
  margin-right:0.5rem;
}

.patch-header{
  font-weight:bold;
  text-transform: uppercase;
}

.patch-volume{
  text-transform: uppercase;
}

.patch-patch_count{
  /*D39D60*/
  letter-spacing: 0.12em;
  color:#CCC200;
  font-weight: bold;
  text-transform: uppercase;
}
.patch-download-type{
  letter-spacing: 0.06em;
}

.patch-offer-details{
  font-size:0.7em;
  text-transform: uppercase;
}

.patch-offer{
  text-transform: uppercase;
}

.patch-group-description ul{
   columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: unset;
}

.patch-group-description p{
  margin-bottom: unset;
}

.button-paypal:hover{
  cursor: pointer;
}

.patch-cover:hover{
  cursor: pointer;
  border:1px solid white;
}

