.menuOpened{
	height:100%;
}
 
/* Created starting from https://codeconvey.com/responsive-hamburger-menu-css-only/ */
/* menu icon */
.toggle-topbar .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}
 
.toggle-topbar .menu-icon .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}
 
.toggle-topbar .menu-icon .navicon:before,
.toggle-topbar .menu-icon .navicon:after {
  background: #fff;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}
 
.toggle-topbar .menu-icon .navicon:before {
  top: 5px;
}
 
.toggle-topbar .menu-icon .navicon:after {
  top: -5px;
}
 
/* menu btn */
.toggle-topbar .menu-btn {
  display: none;
}
 
.toggle-topbar .menu-btn:checked ~ .menu {
  max-height: 340px;
}
 
.toggle-topbar .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
 
.toggle-topbar .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
 
.toggle-topbar .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
 
.toggle-topbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.toggle-topbar .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
