

.modal {
  font-size: 20px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  z-index:1;
}


.display-enter {
  top: var(--menu-starting-top);
  width: var(--toggler-width);
  max-height: var(--toggler-height);
  color: var(--fade-from-color);
  background-color: var(--toggler-bg-color);
}

.display-enter-active {
  top: var(--menu-ending-top);
  width: var(--menu-width);
  max-height: var(--menu-max-height);
  color: var(--fade-to-color);
  background-color: var(--menu-bg-color);
  transition: all var(--timeout);
}

.display-exit {
  top: var(--menu-ending-top);
  width: var(--menu-width);
  max-height: var(--menu-max-height);
  color: var(--fade-to-color);
  background-color: var(--menu-bg-color);
}

.display-exit-active {
  top: var(--menu-starting-top);
  width: var(--toggler-width);
  max-height: var(--toggler-height);
  color: var(--fade-from-color);
  background-color: var(--toggler-bg-color);
  transition: all var(--timeout);
}