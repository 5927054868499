.news-post-content p{
  line-height: 1.2 !important;
}

.news-post-title{
  color: #D39D60;
}

.news-post-type{
  display: inline-block;
  color:#ff3366;
}

.news-post-created_at{
  color:#999;
}

button[type="submit"]:disabled {
  color: #666 !important;
}