.ReactModalPortal{
position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:1;
}
.close{
	margin: 6px;
}
.popup-content{
	background:none;
	width:100% !important;
}

.popup-overlay{
	overflow:auto;
}

.popup-content{
  background:unset !important;
  border:unset !important;
}

 @media only screen and (max-width: 767px) {
  .popup-content{
    width:90% !important;
  }

  #contact .panel {
      margin-left: 0;
      margin-right: 0;
    }
}

 @media only screen and (min-width: 768px) and (max-width: 1024px) {
  .popup-content{
    width:60% !important;
  }
}

 @media only screen and (min-width: 1024px) and (max-width: 1600px) {
  .popup-content{
    width:50% !important;
  }
}

 @media only screen and (min-width: 1600px) {
  .popup-content{
    width:40% !important;
  }
}

#contact .panel{
  border: 1px solid white;
}

h4.modal-title{
  border-bottom: 1px solid #D39D60;
    padding-bottom: 3px;
  color:#D39D60 !important;
}