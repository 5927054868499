.gradient-right {
  position:absolute; right:0; top:0;
}

.gradient-left {
  position:absolute; left:0; top:0;
}

.row{
	background-color:#11120f;
}

.headerContainer{
	max-width: 1280px; 
	margin:0 auto;
}

.logo{
	color:white;
}

.jdsub{
	 opacity: 0;
}

.secondLevelOption{
	padding-right:7px;
}

.jdItem{
	opacity: 1; width: 673px;
}

#submenu-inner-men{
	display: none;
}

.jdmenu{
	position:relative; height:16px; width:100%
}

.jdsubmenu{
	display:block; position:absolute; left:0;
}

.toplevel span:hover{
	font-size:1.04em;
}