.packTitle{
	background-color:#1e1b1c;
}

#jdpage-videos{
	height:100%;
}
.packSubtitle{
	color:#c55760;
	font-weight: bold;
}

#jd-fullscreen-video{
	display: none;
}
.videoContainerColumn{
	position:relative; height:100%; overflow-y:auto; -webkit-overflow-scrolling: touch;
}

.short-jdvideos{
	display: flex;
	justify-content: center;
}